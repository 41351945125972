import React from "react";
import { Provider } from "react-redux";
import AppRoutes from "./AppRoutes";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Home from "./containers/Home";
import Reset from "./containers/Reset";
import LogIn from "./containers/LogIn";
import SignUp from "./containers/SignUp";
import Register from "./containers/Register";
import Contact from "./containers/Contact";
// import Faq from "./containers/Faq";
import Dispatch from "./containers/Dispatch";
import Pricing from "./containers/Pricing";
import Equipment from "./containers/Equipment";
import Verified from "./containers/Verified";
import Gps from "./containers/Gps";
import GpsApi from "./containers/Gps/GpsApi";
import Eld from "./containers/Eld";
import TermsOfService from "./containers/TermsOfService";
import TermsDownload from "./containers/TermsDownload";
import PrivacyPolicy from "./containers/PrivacyPolicy";
import Footer from "./containers/Footer";
import AllApps from "./containers/AllApps";
// import Blog from "./containers/Blog";

import Carrier from "./containers/Carrier";
import Brokerage from "./containers/Brokerage";
import Recruiting from "./containers/Recruiting";
import Accounting from "./containers/Accounting";
import Tracking from "./containers/Tracking";
import Compliance from "./containers/Compliance";
import Aos from 'aos';
import 'aos/dist/aos.css';
import Helmet from "react-helmet";
import ReactGA from 'react-ga';

class App extends React.Component {
    componentDidMount() {
        Aos.init({
            duration: 1800,
        })
        if (process.env.REACT_APP_DOMAIN === '.osrit.com') {
            const TRACKING_ID = "G-XRX72N299M"; // YOUR_OWN_TRACKING_ID
            ReactGA.initialize(TRACKING_ID);
        }
    }


    render() {

        const data = {
            "@context": "https://schema.org",
            "@type": "Organization",
            "name": "Osrit Trucking Software",
            "url": "https://www.osrit.com/",
            "logo": "https://www.osrit.com/logo.png",
            "alternateName": "Osrit",
            "sameAs": [
                "https://www.instagram.com/osritsoftware/",
                "https://www.facebook.com/OsritTruckingSoftware/",
                "https://www.pinterest.com/OsritTruckingSoftware",
                "https://twitter.com/DashOsrit"
            ],
            "contactPoint": [
                {
                    "@type": "ContactPoint",
                    "telephone": "+1 (888) 740 0937",
                    "contactType": "customer service",
                    "email": "contactus@asritsolutions.com",
                    "contactOption": "TollFree",
                    "areaServed": [
                        "US",
                        "CA",
                        "MX"
                    ],
                    "availableLanguage": "en"
                }
            ]
        }
        return (
            <Provider store={this.props.store}>
                <Router>
                    <React.Fragment>
                        <Helmet>
                            <script type="application/ld+json">
                                {JSON.stringify(data)}
                            </script>
                        </Helmet>
                        <div style={{ minHeight: 700 }}>
                            <Route
                                exact={true}
                                path={AppRoutes.home}
                                component={Home}
                            />

                            <Route path={AppRoutes.login} component={LogIn} />
                            <Route path={AppRoutes.reset} component={Reset} />
                            <Route path={AppRoutes.signup} component={SignUp} />
                            <Route path={AppRoutes.register} component={Register} />
                            <Route path={AppRoutes.contact} component={Contact} />
                            <Route path={AppRoutes.verified} component={Verified} />
                            {/* <Route path={AppRoutes.faq} component={Faq} /> */}
                            <Route path={AppRoutes.dispatch} component={Dispatch} />
                            <Route path={AppRoutes.equipments} component={Equipment} />
                            <Route path={AppRoutes.pricing} component={Pricing} />
                            <Route path={AppRoutes.gps} component={Gps} />
                            <Route path={AppRoutes.gpsApi} component={GpsApi} />
                            <Route path={AppRoutes.eld} component={Eld} />
                            <Route path={AppRoutes.terms} component={TermsOfService} />
                            <Route path={AppRoutes.termsDownload} component={TermsDownload} />
                            <Route path={AppRoutes.policy} component={PrivacyPolicy} />
                            <Route path={AppRoutes.policy2} component={PrivacyPolicy} />
                            <Route path={AppRoutes.policyMobile} component={PrivacyPolicy} />
                            {/* <Route path={AppRoutes.blog} component={Blog} /> */}

                            <Route path={AppRoutes.carrier} component={Carrier} />
                            <Route path={AppRoutes.brokerage} component={Brokerage} />
                            <Route path={AppRoutes.recruiting} component={Recruiting} />
                            <Route path={AppRoutes.accounting} component={Accounting} />
                            <Route path={AppRoutes.tracking} component={Tracking} />
                            <Route path={AppRoutes.compliance} component={Compliance} />
                            <Route path={AppRoutes.allApps} component={AllApps} />
                            <Route path={AppRoutes.contactUs} component={Home} contactUs={true} />

                        </div>
                        {/* <div>
                            <a
                                // style={{ marginBottom: "20px" }}
                                href={`https://wa.me/18887400937?text=I have a query regarding`}
                                className="whatsapp_float ml-2"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <i className="fa fa-whatsapp whatsapp-icon"></i>
                            </a>
                        </div> */}
                        <Footer />
                    </React.Fragment>
                </Router>
            </Provider >
        )
    }
};

export default App;
